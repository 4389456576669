import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | DriveCraft Customs & Performance Center
			</title>
			<meta name={"description"} content={"Сучасна станція технічного обслуговування, що спеціалізується на видатному тюнінгу та створенні індивідуальних вихлопних систем для вашого автомобіля."} />
			<meta property={"og:title"} content={"Home | DriveCraft Customs & Performance Center"} />
			<meta property={"og:description"} content={"Сучасна станція технічного обслуговування, що спеціалізується на видатному тюнінгу та створенні індивідуальних вихлопних систем для вашого автомобіля."} />
			<link rel={"shortcut icon"} href={"https://bertapub.net/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://bertapub.net/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://bertapub.net/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://bertapub.net/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://bertapub.net/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://bertapub.net/img/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://bertapub.net/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,--color-light 1.9%,rgba(255, 255, 255, 0.75) 34.8%,rgba(255, 255, 255, 0.5) 76.2%,transparent 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://bertapub.net/img/pexels-jay-pizzle-4756887.jpg) 0% 0% /100% repeat fixed padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-9">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
				>
					DriveCraft Customs & Performance Center{" "}
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="--dark"
					padding="0px 20px 0px 20px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					DriveCraft Customs & Performance Center – це не просто станція технічного обслуговування, це місце, де пристрасть до автомобілів втілюється у життя. Наша компанія заснована на накопиченому досвіді та глибокому розумінні автомобільної індустрії, і ми з гордістю стежимо за розвитком трендів та технологій у світі автомобілів.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="#3f24d8"
						border-radius="8px"
						font="--lead"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="#3f24d8"
						hover-background="rgba(63, 36, 216, 0)"
						hover-color="--darkL1"
						type="link"
						text-decoration-line="initial"
						href="/contact"
					>
						Зв'язатися
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="37%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Ми - це спільнота автолюбителів та професіоналів
				</Text>
			</Box>
			<Box
				display="flex"
				width="62%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Наші співробітники - це висококваліфіковані фахівці, які не тільки мають багатий досвід у технічному обслуговуванні та тюнінгу, а й є справжніми ентузіастами автомобільної культури. Наша команда експертів у світі автотюнінгу готова запропонувати вам визначні рішення для покращення продуктивності, зовнішнього вигляду та звуку вашого автомобіля.{"\n"}
					<br />
					<br />
					{"\n\n"}Ми прагнемо постійного вдосконалення та інновацій. Наша майстерня оснащена сучасним обладнанням та інструментами, що дозволяють нам реалізовувати найскладніші проекти. Разом з "DriveCraft" ви можете очікувати на видатну якість, надійність і безмежну пристрасть до світу автомобілів.
					<br />
					<br />
					{"\n\n\n"}DriveCraft Customs & Performance Center - ваш партнер у створенні ідеального автомобіля, який виділятиметься на дорозі висловлюючи вашу індивідуальність та стиль, і звичайно ж приноситиме незабутні враження від водіння.
				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://bertapub.net/img/pexels-erik-mclean-9983461.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="1 / span 1"
					border-radius="24px"
				/>
				<Image
					src="https://bertapub.net/img/shiny-new-car-auto-service-got-problem-with-tyres-which-will-be-sorted-soon_613910-17078.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="2 / span 2"
					border-radius="24px"
					sm-grid-column="1 / span 1"
				/>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-29">
			<Override
				slot="SectionContent"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
				flex-wrap="wrap"
				md-flex-direction="column"
				sm-min-width="280px"
			/>
			<Box width="30%" sm-width="100%" md-margin="0px 0px 36px 0px">
				<Text margin="0px 0px 0px 0px" font="--headline2" color="--dark" sm-margin="0px 0px 20px 0px">
					Наші переваги
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					sm-margin="0px 0px 20px 0px"
					padding="10px 20px 0px 0px"
				>
					Ми прагнемо не тільки покращити ваш автомобіль, але й створити унікальний досвід для вас, надаючи комфорт та розваги під час очікування. У "DriveCraft Customs & Performance Center" ми приділяємо увагу кожній деталі та працюємо на те, щоб ваш автомобіль став ідеальним втіленням вашої пристрасті до світу автомобілів.
				</Text>
			</Box>
			<Box width="70%" sm-width="100%" md-width="100%" margin="40px 0px 0px 0px">
				<Box
					border-width="0px 0px 1px 0px"
					margin="0px 0px 10px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
				>
					<Text color="--dark" letter-spacing="-.7px" margin="0px 0px 8px 0px" font="--headline3">
						Експертність та досвід
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Наша команда фахівців має великий досвід та глибокі знання у сфері автомобільного тюнінгу та обслуговування. Ми постійно вдосконалюємось і стежимо за останніми трендами, щоб запропонувати вам найпередовіші рішення.
					</Text>
				</Box>
				<Box
					margin="0px 0px 10px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Індивідуальний підхід
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Ми цінуємо унікальність кожного клієнта та автомобіля. Ми працюємо тісно з вами, щоб зрозуміти ваші потреби та надати рішення, які відповідають вашим очікуванням та бюджету.
					</Text>
				</Box>
				<Box
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
					margin="0px 0px 10px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Кастомні вихлопні системи
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Наше вміння створювати унікальні та продуктивні вихлопні системи робить нас лідерами в галузі аудіо-тюнінгу та зовнішнього стилю.
					</Text>
				</Box>
				<Box
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
					margin="0px 0px 10px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Лаундж-зона та комфорт для клієнтів
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						У нас є затишна лаундж-зона, де ви можете розслабитися, випити свіжої кави та ознайомитись із сюжетами доробок автомобілів. Ми цінуємо ваш час та комфорт, тому створили приємну атмосферу для очікування на виконання робіт.
					</Text>
				</Box>
				<Box
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
					margin="0px 0px 10px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Прозорість та зворотній зв'язок
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Ми надаємо прозорі ціни та докладні консультації перед початком робіт. Ви завжди будете в курсі процесу тюнінгу вашого автомобіля, і ми готові відповісти на ваші запитання у будь-який час.
					</Text>
				</Box>
				<Box width="100%" border-color="--color-lightD2" margin="0px 0px 0px 0px">
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Репутація та рекомендації клієнтів
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Безліч задоволених клієнтів та позитивні відгуки підтверджують нашу професійність та надійність.{"\n"}
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link1" />
			<Override slot="box" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652405d247aa25001fafd00a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});